<template>
  <div class="description-wrapper d-flex flex-wrap w-100" v-if="items.length">
    <div
      v-for="item in items"
      :key="item.id"
      class="description-block justify-content-center px-lg-2 text-center"
    >
      <img class="w-auto mb-1  mb-md-0" :src="item.src" :alt="item.title" />
      <div class="description-text  mt-1 pt-lg-2 p-md-1 px-lg-1">
        <h3>{{ item.title }}</h3>
        <p>
          {{ item.subtitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UspBlock",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";

h3 {
  font-size: $font-size-base;
  text-transform: uppercase;
  &:after {
    content: "_";
    color: $primary;
  }
}
img {
  height: 160px;
}
.description-text {
  font-size: $font-size-sm;
}
.description-block {
  font-size: $font-size-sm;
  @include breakpoint(md) {
    width: 50%;
  }
  @include breakpoint(lg) {
    width: 33%;
  }
  &:nth-child(2) {
    img {
      height: 200px;
      margin-top: -43px;
    }
  }
}

.description-wrapper {
  color: $text-color-bright;
  padding: 30px;
  box-shadow: $box-shadow-card;
  background: rgb(25, 29, 32);

  background: linear-gradient(
    45deg,
    rgba(25, 29, 32, 1) 0%,
    rgba(81, 95, 104, 1) 90%
  );
  @include breakpoint(md) {
    padding: 50px 0 30px;
  }
  @include breakpoint(lg) {
    padding: 80px 30px 30px;
  }
}
</style>
