<template>
  <div class="text-center px-1 pt-md-2 pt-4 px-md-3 mb-3 mt-md-4">
    <h2 class="heading">
      <slot />
    </h2>
  </div>
</template>

<script>
export default {
  name: "HeaderHtml"
};
</script>

<style lang="scss" scoped>
@import "../../styles/imports";

.heading {
  font-weight: $font-weight-normal;
  font-size: $font-size-medium;
  line-height: $line-height-base;
  @include breakpoint(md) {
    font-size: $font-size-md;
  }
  @include breakpoint(lg) {
    font-size: $font-size-lg;
  }
}
</style>
