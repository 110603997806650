<template>
  <WhitePageLayout :page-icon="pageIcon">
    <div>
      <div class="container">
        <HeaderHtml>
          <span v-html="$t('pageHeader')"></span>
        </HeaderHtml>

        <UspBlock class="mb-3" :items="$t('usps')" />

        <StoryText class="text-center">
          <template #title>
            <div v-html="$t('texts.0.title')"></div>
          </template>
          <div v-html="$t('texts.0.text')"></div>
        </StoryText>

        <div class="v-grid">
          <div class="col-2 col-offset">
            <VisualizationVideo
              public-id="mediaformat-website/OculusVR-MF.mp4"
            />
          </div>
        </div>

        <GalleryItem
          v-if="galleryImages.length"
          :galleryImages="galleryImages"
          :galleryCurrent="0"
        />
      </div>
    </div>
  </WhitePageLayout>
</template>

<script>
import GalleryItem from "@/components/basic/GalleryItem";
import UspBlock from "@/components/basic/UspBlock";
import { metaInfo } from "@/utils/titleHelper";
import VisualizationVideo from "@/components/story/VisualizationVideo";
import WhitePageLayout from "@/components/layouts/WhitePageLayout";

import pageIcon from "@/assets/img/logo/cube.svg";
import HeaderHtml from "@/components/basic/HeaderHtml";
import StoryText from "@/components/story/StoryText";

import galleryImage1 from "@/assets/img/mf3d/immobilien.png";
import galleryImage2 from "@/assets/img/mf3d/webgl.png";
import galleryImage3 from "@/assets/img/mf3d/schulung.png";
import INNOVATIV_SRC from "@/assets/img/mf3d/INNOVATIV.png";
import GRENZENLOS_SRC from "@/assets/img/mf3d/GREENZENLOS.png";
import EFFIZIENT_SRC from "@/assets/img/mf3d/EFFIZIENT.png";

export default {
  name: "ProjektStarten",
  components: {
    StoryText,
    HeaderHtml,
    WhitePageLayout,
    VisualizationVideo,
    UspBlock,
    GalleryItem
  },
  data() {
    return {
      galleryImages: [],
      pageIcon
    };
  },

  async created() {
    this.galleryImages = this.$t("galleryItems");
  },
  metaInfo() {
    return metaInfo(this.$t("title"), this.$t("descr"));
  },

  i18n: {
    messages: {
      de: {
        title: "🎮 Produkte in VR interaktiv erleben und präsentieren",
        pageHeader: `Faszinierende <strong>3D-Präsentationen</strong> durch den Einsatz von VR oder als Anwendung im Web-Browser.`,
        descr:
          "Faszinierende 3D-Präsentationen durch den Einsatz von VR oder als Anwendung im Web-Browser.",
        texts: [
          {
            title: `
          Bringen Sie Ihre Vertriebswerkzeuge auf <br />
          ein innovatives Level:
        `,
            text: `<p>
            Interaktive 3D-Anwendungen stellen umfangreiche Produktsortimente,
            individuelle Produkt-Konfigurationen oder große Immobilienprojekte
            auf kleinstem physischen Raum dar. Dabei entsteht ein
            <strong>hochemotionales Erlebnis</strong>, welches nachhaltig in
            Erinnerung bleibt. Nutzen Sie diese Vorteile und begeistern Sie Ihre
            Kunden.
          </p>`
          }
        ],
        galleryItems: [
          {
            image: galleryImage1,
            alt: "Immobilien",
            title: "VR im <strong>Wohnbau</strong>",
            body:
              "<strong>Die Herausforderung besteht darin, etwas zu verkaufen das es noch nicht gibt.</strong><p>Noch vor Aushub der Baugrube können Sie mit potenziellen Käufern Architektur, Innenausbau und Lage des zu bauenden Wohnquartiers im virtuellen Raum erleben und so Kaufentscheidungen beschleunigen oder technische Herausforderungen vor Umsetzung untersuchen.</p>"
          },
          {
            image: galleryImage2,
            alt: "Webgl",
            title: "3D im <strong>Web-Browser</strong>",
            body:
              "<strong>Der intuitive Zugang zu Ihrem Produkt: Online in 3D und in allen möglichen Varianten.</strong><p>Individuell konfigurierbare Produkte sind äußerst attraktiv. Für den direkten und unkomplizierten Vertrieb eignen sich 3D-Echtzeit-Konfiguratoren. Mit einer intuitiven und klar strukturierten Benutzeroberfläche verwandeln Sie die individuellen Bedürfnisse Ihrer Kunden in automatisiert generierte Angebote und bestenfalls zu lohnenden Aufträgen.</p>"
          },
          {
            image: galleryImage3,
            alt: "Schulung",
            title: "VR für <strong>Schulungszwecke</strong>",
            body:
              "<strong>Komplizierte Arbeitsschritte an Maschinen im virtuellen Raum gefahrenlos erlernen.</strong><p>Virtual Reality Anwendungen können als betriebsinterne Tools komplexe Prozesse oder Szenarien simulieren und so die Aus- und Weiterbildung von spezialisierten Fachkräften unterstützen. Der große Vorteil: Die digitalen Lernanwendungen können jederzeit unabhängig von Ort und Schulungspersonal genutzt werden.</p>"
          }
        ],
        usps: [
          {
            title: "Innovativ",
            subtitle: `Wir machen aus Ihrem Produkt im digitalen Showroom auf Messen oder im Web ein interaktives Erlebnis.`,
            src: INNOVATIV_SRC
          },
          {
            title: "Grenzenlos",
            subtitle: `Mit uns erweitern Sie den physisch begrenzten Raum – virtuell. Ihr Produkt wird in einem natürlichen Kontext überzeugend in Szene gesetzt.`,
            src: GRENZENLOS_SRC
          },
          {
            title: "Effizient",
            subtitle: `Wir stärken mit virtuellen Protopyen Kaufentscheidungen und optimieren gleichzeitig Vertriebsbudgets.`,
            src: EFFIZIENT_SRC
          }
        ]
      },
      en: {
        title: "🎮 Experience products and presentations in VR",
        pageHeader: `Fascinating <strong>3D presentations</strong> as browser-based web applications or virtual reality.`,
        descr:
          "Fascinating 3D presentations as browser-based web applications or virtual reality",
        texts: [
          {
            title: `Raise your marketing tools to the next level`,
            text: `<p>Interactive 3D applications can present a wide range of products, custom projects and large real estate developments even in the smallest physical space. The result is a <strong>highly engaging and motivating experience</strong> that will be remembered for a long time. Use the 3D experience to inspire and enthuse your customers.</p>`
          }
        ],
        galleryItems: [
          {
            image: galleryImage1,
            alt: "Immobilien",
            title: "VR in <strong>construction</strong>",
            body: `<strong>It is a challenge to sell a product that does not yet exist.</strong>
            <p>Let your potential buyers experience the location, architecture and interior design of your planned housing in a virtual space before the foundations have even been dug. Accelerate purchase decisions and overcome technical challenges before construction starts.</p>`
          },
          {
            image: galleryImage2,
            alt: "Webgl",
            title: "3D in the <strong>web browser</strong>",
            body: `<strong>The intuitive access to your product: online in 3D and in all possible variations.</strong>
            <p>Individually configurable products are extremely attractive. 3D real-time configurators are suitable for direct and uncomplicated sales. With an intuitive and clearly structured user interface, you can transform the individual needs of your customers into automatically generated offers and, at best, worthwhile orders.</p>`
          },
          {
            image: galleryImage3,
            alt: "Schulung",
            title: "VR for <strong>training purposes</strong>",
            body: `<strong>Learn complicated work steps on machines in virtual space without danger.</strong><p>Virtual Reality applications can be used as in-house tools to simulate complex processes or scenarios and thus support the training and further education of specialized personnel. The big advantage: the digital learning applications can be used at any time, regardless of location and training personnel.</p>`
          }
        ],
        usps: [
          {
            title: "Innovative",
            subtitle: `Transform your product into an interactive experience in a virtual showroom at trade fairs or on the web.`,
            src: INNOVATIV_SRC
          },
          {
            title: "Limitless",
            subtitle: `Go beyond your physically limited space – virtually. Present your product convincingly in its natural context.`,
            src: GRENZENLOS_SRC
          },
          {
            title: "Efficient",
            subtitle: `Take advantage of virtual prototypes to make better purchasing decisions and optimize your budget.`,
            src: EFFIZIENT_SRC
          }
        ]
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/imports";
</style>
